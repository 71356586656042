import React from 'react';
import {useTranslation} from "react-i18next";

type Props = {
    name: string;
    title: string;
    message: string;
    onSubmit: () => void;
};

export default function ConfirmMessage({name, title, message, onSubmit}: Props) {
    
    const {t} = useTranslation();

    return (    
        <dialog id={name} className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
                <h3 className="font-bold text-xl">{title}</h3>
                <p className="mt-4">{message}</p>
                <div className="modal-action">
                <form method="dialog">
                    <button className="btn mr-2">{t("cancel")}</button>
                    <button onClick={onSubmit} className="btn btn-primary">{t("yes")}</button>
                </form>
                </div>
            </div>
        </dialog>
    );
}