import React, {useEffect, useState} from 'react';
import logo from '../../../../assets/images/Clark_LogoNoir.png';
import logoEthique from '../../../../assets/images/charte-ethique.png';
import logoEthiqueEn from '../../../../assets/images/charte-ethique-en.png';
import {useTranslation} from "react-i18next";

import "./CertifiedInfluencers.scss"
import FormInput from "../../../FormInput";
import {useFirebase} from "../../../../contexts/FirebaseContext";
import {Influencer, Networks} from "../../../../types/dbModels";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectNetwork from "../../../SelectNetwork";
import InfluencerSearchDetailsModal from "../../../modal/InfluencerSearchDetailsModal";

const CertifiedInfluencers = () => {
    const [searchValue, setSearchValue] = useState<string>("")
    // const [currentPage, setCurrentPage] = useState(1);
    const [influencers, setInfluencers] = useState<Influencer[]>([]);
    const [selectedNetwork, setSelectedNetwork] = useState<Networks | null>(null);
    const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>(influencers);
    const [searchInfluencers, setSearchInfluencers] = useState<Influencer[]>(influencers);
    const [selectedInfluencer, setSelectedInfluencer] = useState<Influencer>();
    const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "fr");

    const { getAllInfluencersWithCodeOfEthics } = useFirebase();
    const {t} = useTranslation();

    const [pagiationLimit, setPaginationLimit] = useState(1);

    const influencerModalName = 'influencer_details_modal';

    useEffect(() => {
        getAllInfluencersWithCodeOfEthics().then(i => {
            setInfluencers(i)
            setFilteredInfluencers(i)
            setSearchInfluencers(i)
            // setCurrentPage(1);
        })
    }, []);

    useEffect(() => {
        if (filteredInfluencers.length > 0) {
            const timer = setTimeout(() => {
                updateSearchInfluencers()
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [searchValue]);

    useEffect(() => {
        if (searchValue) {
            updateSearchInfluencers();
        } else {
            setSearchInfluencers(filteredInfluencers);
        }
    }, [filteredInfluencers]);

    useEffect(() => {
        if (selectedInfluencer) {
            showDetailModal()
        }
    }, [selectedInfluencer])

    const showDetailModal = () => {
        (window as any)[influencerModalName].showModal();

    }

    const updateNetwork = (network: Networks | null) => {
        let newValue: Influencer[] = [];
        setSelectedNetwork(network)
        if (!network) {
            newValue = influencers
            setFilteredInfluencers(newValue)
        } else {
            newValue = influencers.filter(i => {
                return !!i[network]
            })
            setFilteredInfluencers(newValue)
        }

        // setCurrentPage(1);
    }

    const updateSearchInfluencers = () => {
        if (!searchValue) {
            setSearchInfluencers(filteredInfluencers)
        }

        const i = filteredInfluencers.filter(i => {
            let influencerUsername = i.youtube?.profile.username;
            if (selectedNetwork) {
                influencerUsername = i[selectedNetwork]?.profile.username
            } else {
                if (i.tiktok) {
                    influencerUsername = i.tiktok.profile.username
                } else if (i.instagram) {
                    influencerUsername = i.instagram.profile.username
                }
            }

            return (influencerUsername || "").toLowerCase().includes(searchValue.toString())
        });

        setSearchInfluencers(i);
        // setCurrentPage(1);
    }

    // const prevPage = () => {
    //     const canNextPage = (currentPage - 1) >= 1;
    //     if (canNextPage) {
    //         setCurrentPage(p => {
    //             return p - 1;
    //         })
    //     }
    // }
    // const nextPage = async () => {
    //     const maxPage = searchInfluencers.length / pagiationLimit;
    //     const canNextPage = (currentPage + 1) <= maxPage;
    //     if (canNextPage) {
    //         setCurrentPage(p => {
    //             return p + 1;
    //         })
    //     }
    // }

    const getInfluencerData = (influencer: Influencer) => {
        let network: Networks = "youtube";
        if (influencer.tiktok) {
            network = "tiktok";
        } else if (influencer.instagram) {
            network = "instagram"
        }

        return {
            id: influencer.id,
            picture: influencer[network]?.profile.picture,
            username: influencer[network]?.profile.username,
            network
        }
        // titok > insta > youtube
    }

    return (
        <div className='container min-h-screen pb-20'>
            <div className="flex justify-center">
                <img src={language === "fr" ? logoEthique : logoEthiqueEn} className="my-8" width={350} height={"auto"} alt={'Logo'}/>
            </div>

            <div className="card bg-base-200">
                <div className="card-body">
                    <div className="flex flex-col items-center">
                        <h1 className='text-3xl text-center'>
                            {t("ethical-list.title")}
                        </h1>
                        <p>{t("ethical-list.subtitle")}</p>
                        <div className="inputs my-2 flex gap-10">
                            <SelectNetwork value={selectedNetwork} onChange={updateNetwork} allowNull={true}/>
                        </div>
                        <div className="inputs my-2 flex gap-10">
                            <div className="form-control">
                                <FormInput
                                    clearable={false}
                                    type="text"
                                    onChange={event => {
                                        setSearchValue(event.value as string)
                                    }}
                                    name="name"
                                    value={searchValue}
                                    placeholder={t("ethical-list.search")}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="influencers flex flex-wrap gap-6 justify-center">
                        {searchInfluencers
                            // .filter((influencer, index) => {
                            //     const minIndex = currentPage === 1 ? 0 : currentPage * pagiationLimit - 1;
                            //     const maxIndex = minIndex + pagiationLimit - 1;
                            //
                            //     return minIndex <= index && maxIndex >= index;
                            // })
                            .map(influencer => {
                                const data = getInfluencerData(influencer);
                                return <div className="influencer-ethic" key={influencer.userId} onClick={() => {
                                    if (influencer !== selectedInfluencer) {
                                        setSelectedInfluencer(influencer)
                                    } else {
                                        showDetailModal()
                                    }
                                }}>
                                    <div className="img-container">

                                        <img src={data.picture}
                                             alt={data.username + " " + data.network + " profile image"}/>
                                    </div>
                                    <div className="network-img-container">
                                        <FontAwesomeIcon icon={['fab', data.network]} size={'2xl'}/>
                                    </div>
                                    <div className="text-container">
                                        @{data.username}
                                    </div>
                                </div>
                            })}
                    </div>
                </div>
            </div>


            <InfluencerSearchDetailsModal
                name={influencerModalName}
                influencerId={selectedInfluencer?.userId as string}
                platform={selectedInfluencer?.tiktok ? "tiktok" : (selectedInfluencer?.instagram ? "instagram" : "youtube")}
                getFromFirebase={true}
                manageProfilAsClark={false}
                showInModal={true}
                showInCustomerViewMode={true}
                shouldBlur={true}
            />


        </div>
    )
}

export default CertifiedInfluencers;
